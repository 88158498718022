
import dayjs from 'src/lib/dayjs.config';
import extractLocale from 'src/locales/extractLocale'
import { modifyUrl } from 'vike/modifyUrl'

function onBeforeRoute(pageContext) {
  const url = pageContext.urlParsed
  const { urlPathnameWithoutLocale, locale } = extractLocale(url.pathname)

  dayjs.locale(locale);

  // Get the origin dynamically
  let urlLogical = modifyUrl(url.href, { pathname: urlPathnameWithoutLocale })
  if (urlLogical.startsWith("http")) {
    urlLogical = urlLogical.replace(/^https?:\/\/[^/]+/, '');
  }

  return {
    pageContext: {
      // Make `locale` available as pageContext.locale
      locale,
      // Vike's router will use pageContext.urlLogical instead of pageContext.urlOriginal
      urlLogical
    }
  }
}
export default onBeforeRoute
